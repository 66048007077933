.error-overlay-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.error-overlay-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  width: 93%;
  margin: 20px auto;
  max-width: 700px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
}

.error-overlay-title-container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error-overlay-warning-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  background-color: rgba(255, 166, 0, 0.516);
  padding: 10px 3px;
  margin-right: 15px;
  border-radius: 1000px;
}

.error-overlay-title {
  font-size: 26px;
  font-weight: bold;
}

.error-overlay-subtitle {
  font-size: 16px;
  padding-left: 77px;
  padding-right: 20px;
}

.error-overlay-footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
  width: 100%;
  background-color: #f9fafb;
}

.background-full-image {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}