.btn-primary {
  background-color: var(--brand-color) !important;
  border: none !important;
}

.brc-blue-m2,
.brc-h-blue-m2:hover,
.btn-a-outline-primary:not(:disabled):not(.disabled):active,
.btn-a-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-primary.dropdown-toggle {
  border-color: var(--brand-color) !important;
}

.btn {
  border-radius: 0px !important;
}

.btn-a-primary:not(:disabled):not(.disabled).active {
  background-color: transparent;
  border-color: transparent;
}

.btn-a-outline-primary:not(:disabled):not(.disabled):active,
.btn-a-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-a-outline-primary.dropdown-toggle {
  color: #41464d !important;
}

//.btn-a-primary:not(:disabled):not(.disabled):active,
//.btn-a-primary:not(:disabled):not(.disabled).active,
//.show > .btn.btn-a-primary.dropdown-toggle {
//  background-color: transparent;
//  border-color: transparent;
//}

.btn .svg-icon {
  position: relative;
  top: -1px;
}

.btn-mid {
  font-size: 12px !important;
  padding: 10px 16px !important;
}

.btn-outline-light {
  background-color: initial;
  border-color: #d4d7dd;
  color: #91969b;
}

.btn-outline-secondary {
  background-color: initial;
  border-color: #86a0b2;
  color: #4e748d;
}

.btn-light-default,
.btn-a-primary {
  color: var(--brand-color) !important;
}

.btn-xs {
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
}

.btn {
  border-radius: 2px !important;
}

.btn-primary,
.btn-secondary,
.btn-outline-primary {
  font-size: 16px;
  padding: 8px !important;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--brand-secondary) !important;
  border-color: var(--brand-secondary) !important;
  color: #fff !important;
}

.btn-secondary-light,
.btn-secondary-light:active,
.btn-secondary-light:hover,
.btn-secondary-light:focus {
  background-color: #bcbdbf !important;
  border-color: #bcbdbf !important;
  color: #fff !important;
}

.btn-secondary-mid,
.btn-secondary-mid:active,
.btn-secondary-mid:hover,
.btn-secondary-mid:focus {
  background-color: #888 !important;
  border-color: #888 !important;
  color: #fff !important;
}

.btn-green,
.btn-green:active,
.btn-green:hover,
.btn-green:focus {
  background-color: #019444 !important;
  border-color: #019444 !important;
  color: #fff !important;
}

.btn-secondary.time-dropdown-custom,
.btn-secondary.time-dropdown-custom:active,
.btn-secondary.time-dropdown-custom:hover,
.btn-secondary.time-dropdown-custom:focus {
  background-color: transparent !important;
}

.btn-main-custom {
  min-width: 100px;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px !important;
}

